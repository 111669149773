import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';


export default class extends Controller {
  static values = {
    originalPools: Array
  }

  initialize () {
    this.showOffered = localStorage.getItem('showOffered') || 'on';
    this.showWanted = localStorage.getItem('showWanted') || 'on';
    this.calendar = null;
    this.pools = this.originalPoolsValue;
  }

  connect() {
    this.calendar = this.initializeCalendar();
    this.updateEvents();
  }

  initializeCalendar () {
    var calendarEl = this.element;
    let calendar = new Calendar(calendarEl, {
      firstDay: 1,
      contentHeight: "auto",
      aspectRatio: 1.5,
      headerToolbar: {
        left: 'title',
        center: '',
        right: 'today prev,next'
      },
      plugins: [ dayGridPlugin ],
      initialView: 'dayGridMonth',
      viewDidMount: () => {
        var middleToolbarEl = calendarEl.querySelectorAll('.fc-toolbar-chunk')[1];
        middleToolbarEl.innerHTML = `<div class="grid grid-cols-2 gap-x-10">
          <div class="flex cursor-pointer items-center" id="toggle-wanted">
          <button type="button" class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
          <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
          </button>
          <span class="ml-3 text-sm">
            <span class="font-medium text-gray-900">Show Wanted</span>
          </span>
          </div>

          <div id="toggle-offered" class="flex cursor-pointer items-center">
          <button type="button" class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
          <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
          </button>
          <span class="ml-3 text-sm">
            <span class="font-medium text-gray-900">Show Offered</span>
          </span>
          </div>
        </div>`;
        this.toggleContainer = middleToolbarEl;

        this.setToggle(this.showOffered, "#toggle-offered", "bg-blue-500");
        this.setToggle(this.showWanted, "#toggle-wanted", "bg-red-500");

        this.toggleContainer.querySelector('#toggle-wanted').addEventListener('click', () => {
          this.showWanted = this.showWanted === "on" ? "off" : "on";
          localStorage.setItem('showWanted', this.showWanted);

          this.setToggle(this.showWanted, "#toggle-wanted", "bg-red-500");
          this.updateEvents();
        })

        this.toggleContainer.querySelector('#toggle-offered').addEventListener('click', () => {
          this.showOffered = this.showOffered === "on" ? "off" : "on";
          localStorage.setItem('showOffered', this.showOffered);
          this.setToggle(this.showOffered, "#toggle-offered", "bg-blue-500");
          this.updateEvents();
        })

      },
      eventDidMount: function(info) {
        tippy(info.el, {
          content: `Returning back to ${info.event.extendedProps.returning}`
        });
      },
      events: this.originalPoolsValue
    });
    calendar.render();
    return calendar;
  }

  updateEvents () {
    this.calendar.getEventSources().forEach(eventSource => eventSource.remove());
    this.pools = this.originalPoolsValue.filter(pool => {
      if (this.showOffered === "off" && pool.type === "offered") {
        return false;
      } else if (this.showWanted === "off" && pool.type === "seeking") {
        return false;
      } else {
        return true;
      }
    });

    this.calendar.addEventSource(this.pools);
    this.calendar.updateSize();
  }

  setToggle (state, selector, onColor) {
    if (state === 'on') {
      this.toggleContainer.querySelector(`${selector} button`).classList.remove('bg-gray-200');
      this.toggleContainer.querySelector(`${selector} button`).classList.add(onColor);
      this.toggleContainer.querySelector(`${selector} button span`).classList.remove('translate-x-0');
      this.toggleContainer.querySelector(`${selector} button span`).classList.add('translate-x-5');
    } else {
      this.toggleContainer.querySelector(`${selector} button`).classList.remove(onColor);
      this.toggleContainer.querySelector(`${selector} button`).classList.add('bg-gray-200');
      this.toggleContainer.querySelector(`${selector} button span`).classList.remove('translate-x-5');
      this.toggleContainer.querySelector(`${selector} button span`).classList.add('translate-x-0');
    }
  }
}
