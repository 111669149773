import { Application } from '@hotwired/stimulus'
import { Modal, Tabs } from "tailwindcss-stimulus-components"
import Rails from "@rails/ujs"

import { registerControllers } from 'stimulus-vite-helpers'


const application = Application.start()
application.register('modal', Modal);
application.register('tabs', Tabs)


const controllers = import.meta.glob("./**/*_controller.js",{ eager: true })
registerControllers(application, controllers)
Rails.start();
