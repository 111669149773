import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import 'flatpickr/dist/flatpickr.min.css'

export default class extends Controller {
  static targets = ["leavingDate", "returningDate", "seeking", "spacesLabel"]

  connect() {
    this.leavingDatePicker = flatpickr(this.leavingDateTarget, {
      minDate: "today",
      dateFormat: "F j, Y"
    })

    this.returningDatePicker = flatpickr(this.returningDateTarget, {
      minDate: "today",
      dateFormat: "F j, Y"
    })

    this.setSpacesLabel()
  }

  resetReturning() {
    this.returningDateTarget.value = this.leavingDateTarget.value
    this.returningDatePicker.setDate(this.leavingDateTarget.value, true)
  }

  setSpacesLabel() {
    if (this.seekingTarget.checked) {
      this.spacesLabelTarget.innerHTML = "Spaces wanted"
    } else {
      this.spacesLabelTarget.innerHTML = "Spaces offered"
    }
  }
}
