import { Modal } from "tailwindcss-stimulus-components"
import { post } from '@rails/request.js'


export default class extends Modal {
  static targets = [ "body", "sendButton", "successMessage", "title", "closeButton" ]
  static values = {
    url: String,
    title: String,
    code: String
  }

  open() {
    this.titleTarget.innerHTML = this.titleValue
    this.bodyTarget.classList.remove("hidden")
    this.sendButtonTarget.classList.remove("hidden")
    this.successMessageTarget.classList.add("hidden")
    this.closeButtonTarget.innerHTML = "Cancel"

    super.open();
  }

  async send () {
    this.sendButtonTarget.disabled = true
    this.sendButtonTarget.innerHTML = "Sending..."

    const body = {
      message_form: {
        body: this.bodyTarget.value
      }
    }

    if (this.codeValue) {
      body.code = this.codeValue
    }

    const response = await post(this.urlValue, {
      contentType: "application/json",
      body: body
    })

    this.sendButtonTarget.disabled = false
    this.sendButtonTarget.innerHTML = "Send Message"

    if (response.ok) {
      this.bodyTarget.value = ""
      this.bodyTarget.classList.add("hidden")
      this.sendButtonTarget.classList.add("hidden")
      this.titleTarget.innerHTML = "Success!"
      this.closeButtonTarget.innerHTML = "Close"
      this.successMessageTarget.classList.remove("hidden")
    } else {
      const body = await response.json
      alert(`Something went wrong: ${body.errors}`)
    }
  }
}
