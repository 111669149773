import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "target" ];
  static classes = [ "notAllowed" ];

  connect () {
    this.populateSelect(true);
  }

  handleSelectChange() {
    this.populateSelect();
  }

  fields () {
    return JSON.parse(this.data.get('fields'));
  }

  populateSelect(initial = false) {
    var regionId = this.sourceTarget.value;
    var fieldId = initial && this.targetTarget.value;

    let options = !fieldId ? [{ id: '', name: 'Please Select...' }] : [];

    if (regionId) {
      options = options.concat(this.fields().filter(field => field.region_id === parseInt(regionId)));
    }

    const selectBox = this.targetTarget;
    selectBox.innerHTML = '';
    options.forEach(item => {
      const opt = document.createElement('option');
      opt.value = item.id;
      opt.text = item.name;

      if (fieldId && parseInt(fieldId) === item.id) {
        opt.setAttribute('selected', 'selected');
      }

      selectBox.appendChild(opt);
    });

    if (options.length > 1) {
      selectBox.removeAttribute('disabled');
      selectBox.classList.remove(this.notAllowedClass);
    } else {
      selectBox.setAttribute('disabled', 'disabled');
      selectBox.classList.add(this.notAllowedClass);
    }
  }
}
